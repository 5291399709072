import React, { useContext, useEffect, useState } from 'react';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';

import { StoreContext } from '../../../context/store-context';
import ProductItemContainer from './ProductItemContainer';
import { isAvailable } from '../../../utils/helpers';

import './ProductsCustomerLove.scss';

SwiperCore.use([Navigation]);

const ProductsCustomerLove = ({
	products,
	allSellingPlanGroups,
	activateCustomProductPersonalization,
}) => {
	const { allProducts } = useContext(StoreContext);
	const [productsToShow, setProductsToShow] = useState([]);

	const productsCustomerLoveTitle = activateCustomProductPersonalization
		? 'Recommended for You'
		: 'What our customers are loving';

	useEffect(() => {
		if (activateCustomProductPersonalization) {
			const productsPersonalization =
				JSON.parse(localStorage.getItem('productPersonalization')) ??
				[];
			let productsToShow = [];
			if (productsPersonalization.length > 0) {
				productsToShow = allProducts?.filter(
					(product) =>
						productsPersonalization?.includes(product?.title) &&
						isAvailable(product)
				);
			} else {
				productsToShow = allProducts
					?.filter(
						(product) =>
							products?.includes(product?.handle) &&
							isAvailable(product)
					)
					?.sort(
						(a, b) =>
							products.indexOf(a.handle) -
							products.indexOf(b.handle)
					);
			}

			setProductsToShow(productsToShow);
		} else {
			const productsToShow = allProducts
				?.filter(
					(product) =>
						products?.includes(product?.handle) &&
						isAvailable(product)
				)
				?.sort(
					(a, b) =>
						products.indexOf(a.handle) - products.indexOf(b.handle)
				);
			setProductsToShow(productsToShow);
		}
	}, [activateCustomProductPersonalization]);
	return (
		<div className='products-customer-love'>
			<h2>{productsCustomerLoveTitle}</h2>
			<div className='products-customer-love__products'>
				<Swiper
					spaceBetween={10}
					breakpoints={{
						0: {
							slidesPerView: 2,
						},
						640: {
							slidesPerView: 2,
						},
						768: {
							slidesPerView: 3,
						},
						1024: {
							slidesPerView: 4,
						},
					}}
					navigation={{
						prevEl: '.swiper-button-prev-ms',
						nextEl: '.swiper-button-next-ms',
					}}
				>
					{productsToShow?.map((product) => (
						<SwiperSlide key={product?.id}>
							<ProductItemContainer
								product={product}
								allSellingPlanGroups={allSellingPlanGroups}
							/>
						</SwiperSlide>
					))}
				</Swiper>

				<div id='custom-prev' className='swiper-button-prev-ms' />
				<div id='custom-next' className='swiper-button-next-ms' />
			</div>
		</div>
	);
};

export default ProductsCustomerLove;
