const getUniqueProducts = (orders) => {
    if (!Array.isArray(orders)) return [];

    const uniqueTitles = new Set();

    orders.forEach((product) => {
        const title = product?.edges?.[0]?.node?.title;
        if (title) {
            uniqueTitles.add(title);
        }
    });

    return Array.from(uniqueTitles);
};

export default getUniqueProducts;
