// External
import React, { useContext, useEffect, useState } from 'react';
import cn from 'classnames';

// Internal
import { StoreContext } from '../../../../../../../context/store-context';
import Button from '../../../../../../../components/Button';

import './ProductMiracleButton.scss';
import DangerouslySetHtmlContent from '../../../../../../../components/DangerouslySetHtmlContent';
import SubscriptionModal from '../../../../../../../components/SubscriptionModal';

const ProductMiracleButton = ({
	product,
	oneTime,
	activeSecondLevel,
	recommendedProduct,
	isRecommendedSelected,
	setActiveModal,
	canSubscribe,
	activeRadio,
	selectedValue,
	sellingPlanGroup,
	levelValidator,
	setErrorSelectLevel,
	className,
	isHintActive,
	isMobile,
	usesShadeShotSelection,
	customSubscriptionOptionActive = false,
	setActiveRadio,
	checkVariantSelections,
	showIngredientsModal,
	setShowIngredientsModal,
}) => {
	const {
		addVariantToCart,
		addMultiVariantsToCart,
		setNotificationText,
		setModal,
		showModal,
		isProductInfoButtonVisible,
	} = useContext(StoreContext);
	const [variantIngredients, setVariantIngredients] = useState('');

	const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
	const classes = cn('button button--dark', {
		'pdp-miracle-button--full': oneTime,
		'pdp-miracle-button': !className,
		'pdp-miracle-button-mobile': className === 'mobile',
	});
	const isRecommended = recommendedProduct && isRecommendedSelected;

	const openModalHint = () => {
		setModal(product, 'hint-modal', '', null);
		showModal(true);
	};

	const handleSubmit = () => {
		const isVariantSelectionNoValid = checkVariantSelections();
		if (isVariantSelectionNoValid) {
			return;
		}
		const { storefrontId, title } =
			activeSecondLevel !== 0 && isRecommended
				? product.variants.find(
						(el) =>
							el.storefrontId === activeSecondLevel.storefrontId
				  )
				: product;

		const sellingPlanId =
			canSubscribe && activeRadio === 'auto-delivery'
				? sellingPlanGroup.sellingPlans.edges[selectedValue].node.id
				: null;
		const multiplyItems = isRecommended && [
			{
				variantId: storefrontId,
				quantity: 1,
				attributes: [],
				sellingPlanId,
			},
			{
				variantId: recommendedProduct.id,
				quantity: 1,
				attributes: [],
			},
		];

		if (isRecommended) {
			addMultiVariantsToCart(multiplyItems);
		} else if (activeSecondLevel !== 0) {
			addVariantToCart(
				activeSecondLevel.storefrontId,
				1,
				[],
				false,
				product,
				sellingPlanId
			);
		} else {
			addVariantToCart(
				product.variants[0].storefrontId,
				1,
				[],
				false,
				product,
				sellingPlanId
			);
		}
		setNotificationText(`${product.title} - ${title}`);
		setActiveModal && setActiveModal(false);
	};

	useEffect(() => {
		if (customSubscriptionOptionActive && activeRadio !== 'auto-delivery') {
			setShowSubscriptionModal(true);
		}
	}, [customSubscriptionOptionActive, activeRadio]);

	const showIngredientsPopup = () => {
		const isVariantSelectionNoValid = checkVariantSelections('Ingredients');
		if (isVariantSelectionNoValid) {
			return;
		}
		setShowIngredientsModal(true);
	};

	useEffect(() => {
		const variantIngredients =
			activeSecondLevel?.metafields?.find(
				(variant) => variant.key === 'variant_ingredients'
			)?.value ?? 'INGREDIENTS ARE NOT AVAILABLE';
		setVariantIngredients(variantIngredients);
	}, [activeSecondLevel]);

	return (
		<>
			{showSubscriptionModal && activeRadio !== 'auto-delivery' && (
				<>
					<div
						className='modal-backdrop'
						onClick={() => setShowSubscriptionModal(false)}
						aria-hidden="true"
					/>
					<SubscriptionModal
						setActiveRadio={setActiveRadio}
						setShowSubscriptionModal={setShowSubscriptionModal}
					/>
				</>
			)}
			{showIngredientsModal && (
				<>
					<div
						className='modal-ingredients-background'
						onClick={() => setShowIngredientsModal(false)}
						aria-hidden="true"
					/>
					<div className='product-miracle-actions-v2__popup' role="dialog" aria-labelledby="ingredients-title">
						<div className='product-miracle-actions-v2__popup-title'>
							<h4 id="ingredients-title">Ingredients</h4>
							<button
								onClick={() => setShowIngredientsModal(false)}
								aria-label="Close ingredients modal"
							>
								X
							</button>
						</div>
						<DangerouslySetHtmlContent html={variantIngredients} />
					</div>
				</>
			)}
			<button
				className={classes}
				style={{
					backgroundColor:
						activeSecondLevel === null ? '#E8E8E8' : '#D56E8F',
				}}
				onClick={handleSubmit}
				aria-disabled={activeSecondLevel === null}
			>
				<span
					style={{
						color: activeSecondLevel === null ? '#5C5C5C' : 'white',
					}}
				>
					Add to bag
				</span>
			</button>

			{isHintActive && (
				<div
					style={{
						display: isMobile ? 'none' : 'block',
					}}
				>
					<Button
						isAction
						isPink
						isNotProduct
						buttonText='Send a hint'
						icon='gift.svg'
						handleClick={openModalHint}
					/>
				</div>
			)}

			{isHintActive && isMobile && (
				<div
					className='product-miracle-actions__hint'
					id='hint-button-mobile'
				>
					<Button
						isAction
						isPink
						isNotProduct
						buttonText='Send a hint'
						icon='gift.svg'
						handleClick={openModalHint}
					/>
				</div>
			)}
		</>
	);
};

export default ProductMiracleButton;
