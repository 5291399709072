// External
import React, { useContext, useEffect, useRef } from 'react';

// Internal
import ProductMiracleButton from './ProductMiracleButton';
import { useWindowWidth } from '../../../../../../utils/hooks';
import { getMetafield } from '../../../../../../utils/helpers';
import { StoreContext } from '../../../../../../context/store-context';

const ProductMiracleActions = ({
	product,
	activeRadio,
	activeSecondLevel,
	recommendedProduct,
	isRecommendedSelected,
	setActiveModal,
	canSubscribe,
	selectedValue,
	sellingPlanGroup,
	levelValidator,
	setErrorSelectLevel,
	usesShadeShotSelection,
	customSubscriptionOptionActive,
	setActiveRadio,
	checkVariantSelections,
	showIngredientsModal,
	setShowIngredientsModal,
}) => {
	const width = useWindowWidth();
	const { setIsProductInfoButtonVisible, isProductInfoButtonVisible } =
		useContext(StoreContext);
	const isHintActive = JSON.parse(
		getMetafield(product, 'is_active_send_hint')
	);
	const isMobile = width < 550;

	const buttonRef = useRef(null);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				setIsProductInfoButtonVisible(entry.isIntersecting);
			},
			{
				threshold: 0.2,
				rootMargin: '-80px 0px 0px 0px',
			}
		);

		if (buttonRef.current) {
			observer.observe(buttonRef.current);
		}

		return () => {
			if (buttonRef.current) {
				observer.unobserve(buttonRef.current);
			}
		};
	}, []);

	return (
		<div className='product-miracle-actions-v2 flex'>
			<div
				style={{
					opacity: isProductInfoButtonVisible ? 0 : 1,
					visibility: isProductInfoButtonVisible
						? 'hidden'
						: 'visible',
					transition:
						'opacity 0.3s ease-in-out, visibility 0.3s ease-in-out',
					background: isProductInfoButtonVisible
						? 'rgba(0, 0, 0, 0)'
						: 'white',
				}}
				className='product-bg-mobile'
				id='add-to-bag-sticky-button'
			>
				<ProductMiracleButton
					{...{
						product,
						activeSecondLevel,
						recommendedProduct,
						isRecommendedSelected,
						setActiveModal,
						canSubscribe,
						activeRadio,
						selectedValue,
						sellingPlanGroup,
						levelValidator,
						setErrorSelectLevel,
						isHintActive,
						usesShadeShotSelection,
						customSubscriptionOptionActive,
						setActiveRadio,
						checkVariantSelections,
						showIngredientsModal,
						setShowIngredientsModal,
					}}
					oneTime={activeRadio !== 'auto-delivery'}
					isMobile={isMobile}
				/>
			</div>

			{isMobile && (
				<div
					className='product-miracle-actions-v2__mobile-button'
					ref={buttonRef}
				>
					<ProductMiracleButton
						{...{
							product,
							activeSecondLevel,
							recommendedProduct,
							isRecommendedSelected,
							setActiveModal,
							canSubscribe,
							activeRadio,
							selectedValue,
							sellingPlanGroup,
							levelValidator,
							setErrorSelectLevel,
							isHintActive,
							usesShadeShotSelection,
							customSubscriptionOptionActive,
							setActiveRadio,
							checkVariantSelections,
							showIngredientsModal,
							setShowIngredientsModal,
						}}
						oneTime={activeRadio !== 'auto-delivery'}
						className='mobile'
					/>
				</div>
			)}
		</div>
	);
};

export default ProductMiracleActions;
