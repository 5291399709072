const areProductsEqual = (products1, products2) => {
    if (!products1 || !products2) return false;
    if (products1.length !== products2.length) return false;

    const sortedProducts1 = [...products1].sort();
    const sortedProducts2 = [...products2].sort();
    return sortedProducts1.every(
        (title, index) => title === sortedProducts2[index]
    );
};


export default areProductsEqual;
