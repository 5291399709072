import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

import LocalVideo from '../../LocalVideo';
import TrustPilot from '../../TrustPilot';
import ButtonGradient from '../../ButtonGradient';
import { useWindowWidth } from '../../../utils/hooks';
import videoMSDesktop from '../../../../static/images/home-page/hero-banner/miracle-mask-desktop.mp4';
import videoMSMobile from '../../../../static/images/home-page/hero-banner/miracle-mask-mobile.mp4';
import LocalImage from '../../LocalImage';


const HeroMiracleBundle = ({ promo = null }) => {
	const width = useWindowWidth();
	const isMobile = width < 768;

	const stylesFlashPromo = {
		backgroundColor: promo?.colors,
		backgroundImage: `url(${promo?.image_background_promo?.filename})`,
		backgroundSize: 'cover',
	};

	const stylesTextPromo = {
		color: promo?.colour_text,
	};

	return (
		<>
			{isMobile && (
				<section className='miracle-hero-banner-bg-top'>
					<div className='miracle-hero-banner-bg-top__trustpilot'>
						<TrustPilot id='5419b6ffb0d04a076446a9af' height='23px' />
					</div>
					<h2>
						<span
							id="hero-title-mobile-bundle"
							className='miracle-hero-banner__heading--mobile font-size-title'
						>
							The Miracle System: Strength & Shine Bundle
						</span>
					</h2>

					<h3>
						<span
							id="hero-desc-mobile-bundle"
							className='miracle-hero-banner__heading--mobile homepage-hero font-size-desc'
						>
							Also available on subscription!
						</span>
					</h3>

					{promo?.enable_flash_corner && (
						<Link
							to={`/${promo?.link?.url}`}
							className='miracle-hero-banner-bg-top__promo'
							style={stylesFlashPromo}
							aria-label={`Promo: ${promo?.principal_text} ${promo?.secondary_text}`}
						>
							<p style={stylesTextPromo}>
								{promo?.principal_text}
							</p>
							<p style={stylesTextPromo}>
								{promo?.secondary_text}
							</p>
						</Link>
					)}
				</section>
			)}
			<article className='miracle-hero-banner homepage-hero'>
				<figure className='miracle-hero-banner__video'>
					<div
						id="hero-video-small-bundle"
						className='miracle-hero-banner__video--small bundle'
					>
						<LocalVideo
							source={videoMSMobile}
							className='miracle-hero-banner'
							name='hero-video-small'
							preload='metadata'
							poster='/path/to/poster-image-small.jpg'
						/>
					</div>
					<div
						id="hero-video-large-bundle"
						className='miracle-hero-banner__video--large'
					>
						<LocalVideo
							source={videoMSDesktop}
							className='miracle-hero-banner'
							name='hero-video-large'
							preload='metadata'
							poster='/path/to/poster-image-large.jpg'
						/>
					</div>
				</figure>
				{promo?.enable_flash_corner && (
					<Link
						to={`/${promo?.link?.url}`}
						className='miracle-hero-banner__promo homepage-promo'
						style={stylesFlashPromo}
						aria-label={`Promo: ${promo?.principal_text} ${promo?.secondary_text}`}
					>
						<p style={stylesTextPromo}>{promo?.principal_text}</p>
						<p style={stylesTextPromo}>{promo?.secondary_text}</p>
					</Link>
				)}
				<section className='miracle-hero-banner__content homepage-mobile'>
					{!isMobile && (
						<>
							<TrustPilot
								id='5419b6ffb0d04a076446a9af'
								height='22px'
							/>
							<h3>
								<span
									id="hero-title-desktop-bundle"
									className='miracle-hero-banner__heading--desktop homepage-hero-title font-size-title'
								>
									The Miracle System: Strength & Shine Bundle
								</span>
							</h3>

							<h2>
								<span
									id="hero-desc-desktop-bundle"
									className='miracle-hero-banner__heading--desktop homepage-hero font-size-desc'
								>
									Also available on subscription!
								</span>
							</h2>
						</>
					)}

					<ul
						id="hero-list-desktop"
						className='miracle-hero-banner__content-list mobile-home'
					>
						<li className='miracle-hero-banner__content-list-item'>
							<strong>2.7x </strong> stronger hair
						</li>
						<li className='miracle-hero-banner__content-list-item'>
							<strong>Great smell</strong>, zero ammonia
						</li>
						<li className='miracle-hero-banner__content-list-item'>
							<strong>100% grey coverage</strong>
						</li>
						<li className='miracle-hero-banner__content-list-item'>
							<strong>15%</strong> off every order
						</li>
						<li className='miracle-hero-banner__content-list-item'>
							<strong>Free delivery</strong> with every order
						</li>
						<li className='miracle-hero-banner__content-list-item'>
							<strong>Free gifts</strong> along the way
						</li>
					</ul>

					<footer className='miracle-hero-banner__content-buttons mobile-menu--buttons'>
						<ButtonGradient
							isLink
							isPink
							linkUrl='/products/the-miracle-system-and-miracle-mask-bundle'
							buttonText='Subscribe Now'
						/>
						<LocalImage
							image='/home-page/guarantee.png'
							alt='Money back guarantee'
						/>
					</footer>
				</section>
			</article>
		</>
	);
};

export default HeroMiracleBundle;