import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

import LocalVideo from '../../LocalVideo';
import videoDesktop from '../../../../static/images/home-page/hero-banner/hero-video-desktop.mp4';
import videoMobile from '../../../../static/images/home-page/hero-banner/hero-video-mobile.mp4';
import TrustPilot from '../../TrustPilot';
import ButtonGradient from '../../ButtonGradient';
import { useWindowWidth } from '../../../utils/hooks';
import LocalImage from '../../LocalImage';

import './Hero.scss';

const HeroHomepageV2 = ({ promo = null }) => {
	const width = useWindowWidth();
	const isMobile = width < 768;

	const stylesFlashPromo = {
		backgroundColor: promo?.colors,
		backgroundImage: `url(${promo?.image_background_promo?.filename})`,
		backgroundSize: 'cover',
	};

	const stylesTextPromo = {
		color: promo?.colour_text,
	};

	return (
		<>
			{isMobile && (
				<section className='miracle-hero-banner-bg-top'>
					<div className='miracle-hero-banner-bg-top__trustpilot'>
						<TrustPilot id='5419b6ffb0d04a076446a9af' height='23px' />
					</div>
					<h2>
						<span
							id="hero-title-mobile"
							className='miracle-hero-banner__heading--mobile'
						>
							The Miracle System
						</span>
					</h2>

					<h3>
						<span
							id="hero-desc-mobile"
							className='miracle-hero-banner__heading--mobile homepage-hero'
						>
							Salon quality hair colour that makes you feel like
							you again
						</span>
					</h3>

					{promo?.enable_flash_corner && (
						<Link
							to={`/${promo?.link?.url}`}
							className='miracle-hero-banner-bg-top__promo'
							style={stylesFlashPromo}
							aria-label={`Promo: ${promo?.principal_text} ${promo?.secondary_text}`}
						>
							<p style={stylesTextPromo}>
								{promo?.principal_text}
							</p>
							<p style={stylesTextPromo}>
								{promo?.secondary_text}
							</p>
						</Link>
					)}
				</section>
			)}
			<article className='miracle-hero-banner homepage-hero'>
				<figure className='miracle-hero-banner__video'>
					<div
						id="hero-video-small"
						className='miracle-hero-banner__video--small'
					>
						<LocalVideo
							source={videoMobile}
							className='miracle-hero-banner'
							name='hero-video-small'
							preload='metadata'
							poster='/path/to/poster-image-small.jpg'
						/>
					</div>
					<div
						id="hero-video-large"
						className='miracle-hero-banner__video--large'
					>
						<LocalVideo
							source={videoDesktop}
							className='miracle-hero-banner'
							name='hero-video-large'
							preload='metadata'
							poster='/path/to/poster-image-large.jpg'
						/>
					</div>
				</figure>
				{promo?.enable_flash_corner && (
					<Link
						to={`/${promo?.link?.url}`}
						className='miracle-hero-banner__promo homepage-promo'
						style={stylesFlashPromo}
						aria-label={`Promo: ${promo?.principal_text} ${promo?.secondary_text}`}
					>
						<p style={stylesTextPromo}>{promo?.principal_text}</p>
						<p style={stylesTextPromo}>{promo?.secondary_text}</p>
					</Link>
				)}
				<section className='miracle-hero-banner__content homepage-mobile'>
					{!isMobile && (
						<>
							<TrustPilot
								id='5419b6ffb0d04a076446a9af'
								height='22px'
							/>
							<h3>
								<span
									id="hero-title-desktop"
									className='miracle-hero-banner__heading--desktop homepage-hero-title'
								>
									The Miracle System
								</span>
							</h3>

							<h2>
								<span
									id="hero-desc-desktop"
									className='miracle-hero-banner__heading--desktop homepage-hero'
								>
									Salon quality hair colour that makes you
									feel like you again
								</span>
							</h2>
						</>
					)}

					<ul
						id="hero-list-desktop"
						className='miracle-hero-banner__content-list mobile-home'
					>
						<li className='miracle-hero-banner__content-list-item'>
							<strong>Reduces hair breakage</strong> 62%
						</li>
						<li className='miracle-hero-banner__content-list-item'>
							<strong>Great smell</strong> ammonia-free formula
						</li>
						<li className='miracle-hero-banner__content-list-item'>
							<strong>100% grey coverage</strong> permanent colour
						</li>
					</ul>

					<footer className='miracle-hero-banner__content-buttons mobile-menu--buttons'>
						<ButtonGradient
							isLink
							isPink
							linkUrl='/pages/hair-colour-care-quiz'
							buttonText='Get Your Perfect Match'
						/>
						<LocalImage
							image='/home-page/guarantee.png'
							alt='Money back guarantee'
						/>
					</footer>
				</section>
			</article>
		</>
	);
};

export default HeroHomepageV2;