import React, { useContext, lazy, Suspense } from 'react';

import { StoreContext } from '../../../context/store-context';
import HeroHomepageV2 from '../../../components/HomepageV2/Hero';
import HeroMiracleBundle from '../../../components/HomepageV2/HeroMiracleBundle';
import AsSeenInV2 from '../../../components/AsSeenIn';

import Loader from '../../../components/Loader';

const CombineCollections = lazy(() =>
	import('../../../components/HomepageV2/CombineCollections')
);

const StartOptions = lazy(() =>
	import('../../../components/HomepageV2/StartOptions')
);
const ProductTestimonials = lazy(() =>
	import('../../../templates/products-v2/ProductTestimonials')
);
const YotpoCarousel = lazy(() =>
	import('../../../components/Yotpo/YotpoCarousel')
);
const DifferenceChart = lazy(() =>
	import('../../../components/HomepageV2/DifferenceChart')
);
const ProductHairBenefits = lazy(() =>
	import('../../../templates/products-v2/ProductHairBenefits')
);
const ProductsCustomerLove = lazy(() =>
	import('../../../components/HomepageV2/ProductsCustomerLove')
);
const GeneralInformation = lazy(() =>
	import('../../../components/HomepageV2/GeneralInfo')
);
const HairCareComponent = lazy(() =>
	import('../../../components/HomepageV2/HairCare')
);
const ProductFaq = lazy(() =>
	import('../../../templates/products-v2/ProductFaq')
);
const TextSlider = lazy(() => import('../../../components/TextSlider'));
const ProductAwardsContainer = lazy(() =>
	import(
		'../../../templates/products-v2/ProductsAwardsV2/ProductAwardsContainer'
	)
);
const MoreOptions = lazy(() =>
	import('../../../components/HomepageV2/MoreOptions')
);
const VideoJosh = lazy(() =>
	import('../../../components/HomepageV2/VideoJosh')
);
const CustomerReviews = lazy(() =>
	import('../../../components/HomepageV2/CustomerReviews')
);
const TrustInResults = lazy(() =>
	import('../../../components/HomepageV2/TrustInResults')
);
const InstagramFeed = lazy(() =>
	import('../../../components/HomepageV2/InstragramFeed')
);
const ButtonGradient = lazy(() => import('../../../components/ButtonGradient'));

const HomePageV2 = ({
	pageContextProps: {
		productIdReviews,
		productsCustomerLove,
		allSellingPlanGroups,
		ratingMiracle,
		combineCollections,
		promo,
	},
}) => {
	const { allProducts, globalMetafields } = useContext(StoreContext);
	const products = productsCustomerLove?.split('|');
	const product = allProducts?.find(
		(product) => product.handle === 'miracle-system'
	);
	const promoBanner = promo || null;
	const activateCustomProductPersonalization = globalMetafields?.filter(
		(metafield) =>
			metafield.key === 'enable_personalized_product_recommendations'
	)[0]?.value;
	return (
		<>
			<section id="hero-homepage-v2">
				<HeroHomepageV2 promo={promoBanner} />
			</section>
			<section id="hero-miracle-bundle" style={{ display: 'none' }}>
				<HeroMiracleBundle promo={promoBanner} />
			</section>	

			<AsSeenInV2 row isHomepage />

			<Suspense fallback={<Loader />}>
				<CombineCollections collections={combineCollections} />
				<StartOptions />

				<ProductsCustomerLove
					products={products}
					allSellingPlanGroups={allSellingPlanGroups}
					activateCustomProductPersonalization={Boolean(
						activateCustomProductPersonalization
					)}
				/>

				<GeneralInformation reverse rating={ratingMiracle} />

				<ProductHairBenefits />

				<GeneralInformation contentType='2' />
				<GeneralInformation reverse contentType='3' />

				<HairCareComponent />

				<ProductTestimonials />

				<ProductAwardsContainer product={product} isHomepage />

				<MoreOptions />

				<DifferenceChart />

				<VideoJosh videoId='1012525184' isHomepage />

				<CustomerReviews />

				<YotpoCarousel productId={productIdReviews} />

				<div className='button-homepage-center'>
					<ButtonGradient
						isLink
						isPinkOutline
						linkUrl='/collections/best-selling-products'
						buttonText='Shop all best sellers'
						id='jwc-difference-button'
					/>
				</div>

				<InstagramFeed />

				<ProductFaq />

				<TextSlider isHomepage />

				<TrustInResults />
			</Suspense>
		</>
	);
};

export default HomePageV2;
