import { Link } from 'gatsby';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import getVariantPrice from '../../../helpers/productsPricing';

import {
	getMetafield,
	getSellingPlanGroupDefaultValue,
	getSellingPlanGroupOptions,
} from '../../../utils/helpers';

import ProductSelectionLevels from '../../../templates/products-v2/ProductFirstScreenV2/ProductBundlesSection/ProductSelectionLevels';
import icon from '../../../../static/images/info-icon.svg';
import './MiracleSystem.scss';

const MiracleSystem = ({ product, sellingPlanGroup, description, href, useNewMiracleTemplate }) => {
	const [activeVariant, setActiveVariant] = useState(null);
	const [productImage, setProductImage] = useState(
		product?.media[0]?.preview.image.gatsbyImageData
	);
	const [activeRadio, setActiveRadio] = useState('auto-delivery');
	const [options, setOptions] = useState(
		getSellingPlanGroupOptions(sellingPlanGroup.sellingPlans?.edges)
	);
	const [selectedValue, setSelectedValue] = useState(
		getSellingPlanGroupDefaultValue(options)
	);

	const levelNumber = parseInt(
		getMetafield(product, 'number_of_selection_levels')
	);

	return (
		<section className='miracle-system-upsell'>
			<section className='miracle-system-upsell__image'>
				<GatsbyImage
					image={productImage}
					className='miracle-system-upsell__image-img'
				/>

				<Link to={href}>
					<img
						src={icon}
						className='miracle-system-upsell__image-icon'
						alt='text'
					/>
					<p>more info</p>
				</Link>
			</section>
			<section className='miracle-system-upsell__content'>
				<section className='miracle-system-upsell__content--title'>
					<h5>{product?.title}</h5>
					<p>from {getVariantPrice(product, true, 15)}</p>
				</section>
				<section className='miracle-system-upsell__content--description'>
					<p>{description}</p>
				</section>
				{product && levelNumber && (
					<ProductSelectionLevels
						levelNumber={levelNumber}
						canSubscribe
						product={product}
						activeFirstLevel={activeVariant}
						setActiveFirstLevel={setActiveVariant}
						activeRadio={activeRadio}
						setActiveRadio={setActiveRadio}
						sellingPlanGroup={sellingPlanGroup}
						options={options}
						selectedValue={selectedValue}
						setSelectedValue={setSelectedValue}
						setProductImage={setProductImage}
						isUpsell
						useNewMiracleTemplate={useNewMiracleTemplate}
					/>
				)}
			</section>
		</section>
	);
};

export default MiracleSystem;
