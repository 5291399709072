export default [
	{
		title: 'The best system ever',
		name: 'Jennifer W.',
		text: 'I love the way this is delivered, packaged and how easy it is to mix. The scent is gorgeous and the barrier, stain wipe and gloves are all perfect. My hair has never felt glossier and I honestly get comments on my colour and I never have before! So silky, so shiny and long lasting',
		imgUrl: 'pdp/customers/woman-3.png',
	},
	{
		title: 'This is truly a MIRACLE!',
		name: 'Sarah E.',
		text: 'This system is awesome! My hair is as good as when I’ve been to a salon. I purchase on a subscription and I highly recommend to anyone. Covers my grey, feels in such good condition and so easy to use! Hurrah for Josh Wood. Saves me time and money 💰',
		imgUrl: 'pdp/customers/woman-5.png',
	},
	{
		title: 'Brilliant!',
		name: 'Gillian T.',
		text: 'Best at home hair colour I’ve ever used. Always left hair colouring to my hairdresser but changed my mind after reading the reviews and seeing people’s photos. The shade choices are spot on.  My shade is 6.0 with an icy shot.  I will definitely buy again.',
		imgUrl: 'pdp/customers/woman-2.png',
	},
	{
		title: 'Never disappoints',
		name: 'Alexandra N.',
		text: 'I am always satisfied with the Miracle System, the kit is easy to use every time and provides shine and super soft hair plus it covers all my white hairs 😁. The color is what I need and absolutely fabulous for me. Josh Wood never disappoints!! Thank you so much.',
		imgUrl: 'pdp/customers/woman-4.png',
	},
	{
		title: 'Thank you Josh Wood',
		name: 'Alexandra N.',
		text: "Absolutely love the miracle system, gives my hair a good grey coverage, shine, softness, is the best haircolor on the market. It is easy to find it and order it online. It looks very good with the package and all the instructions are very useful. It is very easy to do it yourself at home. Thank you Josh Wood for looking after everyone's hair.",
		imgUrl: 'pdp/customers/woman-1.png',
	},
	{
		title: 'A good quality product',
		name: 'Denise',
		text: "I use for root colour as I am pepper and salt grey, which means this colour 9.0 lightens the brown slightly and changes the pale grey/white of my natural growth to a really nice pale blonde. The overall result is a lovely colour and doesn't ruin the condition of hair. Very good conditioning treatment included. Instructions are easy to follow.",
		imgUrl: 'pdp/customers/woman-13.webp',
	},
	{
		title: 'Unlike my salon colour it',
		name: 'Gausia',
		text: "Unlike my salon colour it lasts longer , keeps my hair silky, have started using it for the past three months ,and have never looked back,it's an excellent product ,well done josh wood",
		imgUrl: 'pdp/customers/woman-9.webp',
	},
	{
		title: 'Fantastic colour!!',
		name: 'Sarah',
		text: 'Love, love, love the colour, condition and fragrance!!!',
		imgUrl: 'pdp/customers/woman-11.webp',
	},
	{
		title: 'Best hair dye I have ever used x',
		name: 'Nilufer',
		text: 'It was the best hair dye I have genuinely ever used, the best colour aswell. It was so gentle on my hair aswell. So thank you very much.',
		imgUrl: 'pdp/customers/woman-10.webp',
	},
	{
		title: 'Fuss-free colour with lasting results',
		name: 'Natalie P.',
		text: 'A really great colour, perfect for lighter brown hair with warm tones. Covers greys well, and leaves my hair super shiny. A fuss-free, easy to use product that smells divine and gives great lasting results - thank you Josh!',
		imgUrl: 'pdp/customers/woman-12.webp',
	},
	{
		title: 'Covers greys well, smooth & shiny hair!',
		name: 'Nilufer',
		text: 'This is my first home colouring kit & I’m so pleased with the result! It did take me a while to colour my roots & all the way to the tips, but it was worth it! I only missed a small areas of greys, not in obvious places, but the colour looks so natural. It blends well with my own colour, brings our extra shine & smoothness. Overall it makes my hair so healthier, not to mention the lovely smell. The kit has everything you need & in generous quantity. I think the deep conditioner makes a lot of difference to the end result. I strongly recommend it!',
		imgUrl: 'pdp/customers/woman-14.webp',
	},
];
