import React, { useContext } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

import Button from '../../Button';
import icon from '../../../../static/images/info-icon.svg';
import { decodeBase64, getMetafield } from '../../../utils/helpers';
import { StoreContext } from '../../../context/store-context';

import './NormalProduct.scss';

const NormalProduct = ({ product, description, href, currentProduct }) => {
	const { allProducts, replaceCartItem, cart } = useContext(StoreContext);
	const hasMultipleVariants = currentProduct.variants.length > 1;

	const item = !hasMultipleVariants ? cart.lines.edges.find(
		(item) => item.node.merchandise.id === currentProduct.variants[0].storefrontId
	) || null :
		cart.lines.edges.find(
			(item) => currentProduct.variants.find(
				(variant) => variant.storefrontId === item.node.merchandise.id
			)
		) || null;
	const currentVariant = currentProduct.variants.find(
		(variant) =>
			decodeBase64(variant.storefrontId, false) ===
			item?.node?.merchandise?.id
	) || null;
	const upsellBundleResult =
		(currentProduct && getMetafield(currentProduct, 'upsell_result')) ||
		(currentVariant && getMetafield(currentVariant, 'upsell_result'));
	const upsellBundleResultProduct = allProducts?.find(
		(item) => item?.handle === upsellBundleResult
	) || null;
	const upsellBundleResultVariant =
		(currentProduct &&
			getMetafield(currentProduct, 'upsell_result_variant')) ||
		(currentVariant &&
			getMetafield(currentVariant, 'upsell_result_variant'));

	const upsellBundleResultVariantId = upsellBundleResultVariant
		? upsellBundleResultProduct?.variants?.find(
			(variant) => variant?.title === upsellBundleResultVariant
		)?.storefrontId || null
		: upsellBundleResultProduct?.variants[0]?.storefrontId || null;

	const addUpsell = async () => {
		replaceCartItem(
			cart.id,
			item,
			item.node.merchandise.id,
			item.node.id,
			upsellBundleResultVariantId,
			[],
			item.quantity,
			null
		);
	};

	return (
		<section className='normal-product-upsell'>
			<section className='normal-product-upsell__image'>
				<GatsbyImage
					image={product?.media[0]?.preview.image.gatsbyImageData}
					className='normal-product-upsell__image-img'
				/>

				<Link to={href}>
					<img
						src={icon}
						className='normal-product-upsell__image-icon'
						alt='text'
					/>
					<p>more info</p>
				</Link>
			</section>
			<section className='normal-product-upsell__content'>
				<section className='normal-product-upsell__content--title'>
					<h5>{product?.title}</h5>
					<p>£{product?.variants[0]?.price}</p>
				</section>
				<section className='normal-product-upsell__content--description'>
					<p>
						{description}
					</p>
				</section>
				{
					item ? (
						<Button
							isAction
							product={product}
							isDark
							isPdp
							availableForSale={product?.variants[0]?.availableForSale}
							handleClick={addUpsell}
						/>
					) : (
						<Button
							product={product}
							isDark
							isPdp
							availableForSale={product?.variants[0]?.availableForSale}
						/>
					)
				}

			</section>
		</section>
	);
};

export default NormalProduct;
