import React, { useContext, useState } from 'react';

import { StoreContext } from '../../context/store-context';
import MiracleSystem from './MiracleSystem';
import NormalProduct from './NormalProduct';
import { getMetafield, getMetafieldParse } from '../../utils/helpers';
import ProductWithVariants from './ProductWithVariants';

import './UpsellPDP.scss';

const UpsellPDP = ({ sellingPlanGroup, product }) => {
	const { allProducts, variantSelected } = useContext(StoreContext);

	const upsellInfo = getMetafieldParse(product, 'upsell_info');
	const productMatchVariant = getMetafieldParse(variantSelected, 'product_upsell_info');
	const useNewMiracleTemplate = getMetafield(product, 'use_miracle_system_new_pdp');

	const [categorySelected, setCategorySelected] = useState(
		upsellInfo[0].productCategory ?? undefined
	);
	const findProduct = (productId) => {
		const product = allProducts?.find((product) =>
			product.storefrontId.includes(productId)
		);
		return product;
	};
	const handleSelectCategory = (category) => {
		setCategorySelected(category);
	};

	return (
		<section className='upsell-pdp'>
			<h4 className='upsell-pdp__title'>Complete your look</h4>
			<section className='upsell-pdp__select'>
				<ul>
					{upsellInfo?.map((category) => (
						<li key={category.productCategory}>
							<button
								onClick={() =>
									handleSelectCategory(
										category.productCategory
									)
								}
							>
								<div
									className={`option ${categorySelected ===
										category.productCategory &&
										'option-clicked'
										}`}
								>
									{categorySelected ===
										category.productCategory && <div />}
								</div>
								{category.productCategory}
							</button>
						</li>
					))}
				</ul>
			</section>
			<section className='upsell-pdp__product'>
				{upsellInfo?.map((item) => (
					<>
						{item.productCategory === categorySelected && (
							<>
								{categorySelected === 'Tone' && !variantSelected ? (
									<p>Please select a colour</p>
								) : (
									<>
										{item.productType ===
											'TwoVariantsSelect' && (
												<MiracleSystem
													product={findProduct(item?.productId)}
													sellingPlanGroup={sellingPlanGroup}
													description={item.productDescription}
													href={item.route}
													useNewMiracleTemplate={useNewMiracleTemplate}
												/>
											)}
										{item.productType === 'OneVariantSelect' && (
											<ProductWithVariants
												product={findProduct(item?.productId || productMatchVariant?.productId)}
												description={item?.productDescription || productMatchVariant?.productDescription || null}
												href={item?.route || productMatchVariant?.route || null}
											/>
										)}

										{item.productType === 'NormalProduct' && (
											<NormalProduct
												currentProduct={product}
												product={findProduct(
													item?.productId
												)}
												description={item.productDescription}
												href={item.route}
											/>
										)}
									</>
								)}
							</>
						)}
					</>
				))}
			</section>
		</section>
	);
};

export default UpsellPDP;